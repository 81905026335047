/* Webfont: MersadBlack */
@font-face {
  font-family: 'MersadBlack';
  src: url('../fonts/Mersad/MersadBlack.eot');
  src: url('../fonts/Mersad/MersadBlack.otf') format('opentype'),
    url('../fonts/Mersad/MersadBlack.ttf') format('truetype'),
    url('../fonts/Mersad/MersadBlack.woff') format('woff'),
    url('../fonts/Mersad/MersadBlack.woff2') format('woff2'),
    url('../fonts/Mersad/MersadBlack.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadBold */
@font-face {
  font-family: 'MersadBold';
  src: url('../fonts/Mersad/MersadBold.eot');
  src: url('../fonts/Mersad/MersadBold.otf') format('opentype'),
    url('../fonts/Mersad/MersadBold.ttf') format('truetype'),
    url('../fonts/Mersad/MersadBold.woff') format('woff'),
    url('../fonts/Mersad/MersadBold.woff2') format('woff2'),
    url('../fonts/Mersad/MersadBold.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadExtraBold */
@font-face {
  font-family: 'MersadExtBd';
  src: url('../fonts/Mersad/MersadExtraBold.eot');
  src: url('../fonts/Mersad/MersadExtraBold.otf') format('opentype'),
    url('../fonts/Mersad/MersadExtraBold.ttf') format('truetype'),
    url('../fonts/Mersad/MersadExtraBold.woff') format('woff'),
    url('../fonts/Mersad/MersadExtraBold.woff2') format('woff2'),
    url('../fonts/Mersad/MersadExtraBold.eot?#iefix')
      format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadExtraLight */
@font-face {
  font-family: 'MersadExtLt';
  src: url('../fonts/Mersad/MersadExtraLight.eot');
  src: url('../fonts/Mersad/MersadExtraLight.otf') format('opentype'),
    url('../fonts/Mersad/MersadExtraLight.ttf') format('truetype'),
    url('../fonts/Mersad/MersadExtraLight.woff') format('woff'),
    url('../fonts/Mersad/MersadExtraLight.woff2') format('woff2'),
    url('../fonts/Mersad/MersadExtraLight.eot?#iefix')
      format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadLight */
@font-face {
  font-family: 'MersadLight';
  src: url('../fonts/Mersad/MersadLight.eot');
  src: url('../fonts/Mersad/MersadLight.otf') format('opentype'),
    url('../fonts/Mersad/MersadLight.ttf') format('truetype'),
    url('../fonts/Mersad/MersadLight.woff') format('woff'),
    url('../fonts/Mersad/MersadLight.woff2') format('woff2'),
    url('../fonts/Mersad/MersadLight.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadMedium */
@font-face {
  font-family: 'MersadMed';
  src: url('../fonts/Mersad/MersadMedium.eot');
  src: url('../fonts/Mersad/MersadMedium.otf') format('opentype'),
    url('../fonts/Mersad/MersadMedium.ttf') format('truetype'),
    url('../fonts/Mersad/MersadMedium.woff') format('woff'),
    url('../fonts/Mersad/MersadMedium.woff2') format('woff2'),
    url('../fonts/Mersad/MersadMedium.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadRegular */
@font-face {
  font-family: 'Mersad';
  src: url('../fonts/Mersad/MersadRegular.eot');
  src: url('../fonts/Mersad/MersadRegular.otf') format('opentype'),
    url('../fonts/Mersad/MersadRegular.ttf') format('truetype'),
    url('../fonts/Mersad/MersadRegular.woff') format('woff'),
    url('../fonts/Mersad/MersadRegular.woff2') format('woff2'),
    url('../fonts/Mersad/MersadRegular.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadSemiBold */
@font-face {
  font-family: 'MersadSemBd';
  src: url('../fonts/Mersad/MersadSemiBold.eot');
  src: url('../fonts/Mersad/MersadSemiBold.otf') format('opentype'),
    url('../fonts/Mersad/MersadSemiBold.ttf') format('truetype'),
    url('../fonts/Mersad/MersadSemiBold.woff') format('woff'),
    url('../fonts/Mersad/MersadSemiBold.woff2') format('woff2'),
    url('../fonts/Mersad/MersadSemiBold.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MersadThin */
@font-face {
  font-family: 'MersadThin';
  src: url('../fonts/Mersad/MersadThin.eot');
  src: url('../fonts/Mersad/MersadThin.otf') format('opentype'),
    url('../fonts/Mersad/MersadThin.ttf') format('truetype'),
    url('../fonts/Mersad/MersadThin.woff') format('woff'),
    url('../fonts/Mersad/MersadThin.woff2') format('woff2'),
    url('../fonts/Mersad/MersadThin.eot?#iefix') format('embedded-opentype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikBlack */
@font-face {
  font-family: 'GraphikBlack';
  src: url('../fonts/Graphik/GraphikBlack.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikBlackItalic */
@font-face {
  font-family: 'GraphikBlackItalic';
  src: url('../fonts/Graphik/GraphikBlackItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikBold */
@font-face {
  font-family: 'GraphikBold';
  src: url('../fonts/Graphik/GraphikBold.otf');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikBoldItalic */
@font-face {
  font-family: 'GraphikBoldItalic';
  src: url('../fonts/Graphik/GraphikBoldItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikExtralight */
@font-face {
  font-family: 'GraphikExtralight';
  src: url('../fonts/Graphik/GraphikExtralight.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikExtralightItalic */
@font-face {
  font-family: 'GraphikExtralightItalic';
  src: url('../fonts/Graphik/GraphikExtralightItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikLight */
@font-face {
  font-family: 'GraphikLight';
  src: url('../fonts/Graphik/GraphikLight.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikLightItalic */
@font-face {
  font-family: 'GraphikLightItalic';
  src: url('../fonts/Graphik/GraphikLightItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikMedium */
@font-face {
  font-family: 'GraphikMedium';
  src: url('../fonts/Graphik/GraphikMedium.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikMediumItalic */
@font-face {
  font-family: 'GraphikMediumItalic';
  src: url('../fonts/Graphik/GraphikMediumItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikRegular */
@font-face {
  font-family: 'Graphik';
  src: url('../fonts/Graphik/GraphikRegular.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikRegularItalic */
@font-face {
  font-family: 'GraphikRegularItalic';
  src: url('../fonts/Graphik/GraphikRegularItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikSemibold */
@font-face {
  font-family: 'GraphikSemibold';
  src: url('../fonts/Graphik/GraphikSemibold.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikSemiboldItalic */
@font-face {
  font-family: 'GraphikSemiboldItalic';
  src: url('../fonts/Graphik/GraphikSemiboldItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikSuper */
@font-face {
  font-family: 'GraphikSuper';
  src: url('../fonts/Graphik/GraphikSuper.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikSuperItalic */
@font-face {
  font-family: 'GraphikSuperItalic';
  src: url('../fonts/Graphik/GraphikSuperItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikThin */
@font-face {
  font-family: 'GraphikThin';
  src: url('../fonts/Graphik/GraphikThin.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: GraphikThinItalic */
@font-face {
  font-family: 'GraphikThinItalic';
  src: url('../fonts/Graphik/GraphikThinItalic.otf');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

*,
html,
::before,
::after {
  font-family: 'Graphik', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'MersadMed', sans-serif !important;
}
