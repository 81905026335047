@import '/node_modules/flag-icons/css/flag-icons.min.css';
@import 'antd/dist/reset.css';

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.DEFAULT');
}

::before,
::after {
  --tw-content: '';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  // background-color: rgb(250, 250, 250);
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.card-hoverable-actions .action-block {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: #00000000;
}
.card-hoverable-actions .action-block * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.card-hoverable-actions .action-block img {
  opacity: 1;
  width: 100%;
  display: block;
}
.card-hoverable-actions .action-block .action-left,
.card-hoverable-actions .action-block .action-right {
  position: absolute;
  top: 30%;
  overflow-x: auto;
  opacity: 0;
  padding: 5% 5%;
}
.card-hoverable-actions .action-block .action-center {
  position: absolute;
  top: 35%;
  overflow-x: auto;
  opacity: 0;
  padding: 5% 5%;
  line-height: 10px;
}
.card-hoverable-actions .action-block .action-center {
  left: 40%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.card-hoverable-actions .action-block .action-left {
  left: 27%;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
}
.card-hoverable-actions .action-block .action-right {
  right: 27%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.card-hoverable-actions:hover .action-bloc img {
  opacity: 0.6;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.card-hoverable-actions:hover .action-block {
  // background-color: #00000077;
}
.card-hoverable-actions:hover .action-block .action-center,
.card-hoverable-actions:hover .action-block .action-left,
.card-hoverable-actions:hover .action-block .action-right {
  background-color: #0000ffee;
  border-radius: 240px;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
}

.mesh {
  &::before {
    background-image: url('../../public/mesh.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    content: '';
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: -1;
  }
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
}

.gsi-material-button:disabled .gsi-material-button-state {
  background-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #001d35;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #001d35;
  opacity: 8%;
}

.ant-pagination.ant-table-pagination {
  align-items: center !important;
  margin-right: 16px !important;
  line-height: 44px;
}

.ant-pagination-options-size-changer {
  min-height: 44px !important;
}

div.ant-select-selector {
  min-height: 44px !important;
  align-items: center !important;
}

div.ant-pagination-options-quick-jumper input {
  min-height: 44px !important;
  align-items: center !important;
}

span.ant-select-selection-search input {
  min-height: 44px !important;
  align-items: center !important;
}

span.ant-select-selection-item {
  min-height: 32px;
  align-items: center !important;
}

div.ant-color-picker-format-select .ant-select-selector {
  min-height: 24px !important;
  align-items: center !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

.ant-input-password span.ant-input-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1;
}

span.ant-avatar-circle {
  background-color: rgb(80, 80, 80);
}

.text-align-right-input,
.text-align-right-input input {
  text-align: right;
}

/* Drawer */
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  max-width: 80%;
}

.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.1s,
    width 0s ease 0.1s;
}
.ant-drawer > * {
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1),
    box-shadow 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
    9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
    -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
    0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
    0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.1s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(26, 54, 64, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(26, 54, 64, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.1s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(26, 54, 64, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(26, 54, 64, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(26, 54, 64, 0.85);
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 16px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(26, 54, 64, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.1s linear, height 0s ease 0.1s;
  pointer-events: none;
}
.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}

.ant-upload .ant-upload-btn {
  padding: 12px !important;
}

.ant-upload-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.ant-upload-list-item-name {
  white-space: normal !important;
  height: 100%;
  display: none;
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 0px !important;
}

.ant-tabs-tab,
.ant-menu-submenu,
.ant-menu-item,
.ant-table-thead,
.ant-collapse-header {
  user-select: none;
}

.ant-tabs-tab-disabled {
  cursor: unset !important;
  font-size: 10px !important;
  color: rgba(0, 0, 0, 0.5) !important;
  text-transform: uppercase !important;
  padding-bottom: 0px !important;
}

@media (max-width: 767px) {
  [data-layout='layout'] {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  [data-layout='layout'] .left-sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    height: 100%;
    width: 16rem;
    --tw-translate-x: -16rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }

  [data-layout='layout'][data-collapsed='true'] .left-sidebar {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .left-sidebar {
    font-size: 0.875rem;
    line-height: 1.25rem;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -4px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
      0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (min-width: 768px) {
  [data-layout='layout'] {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  [data-layout='layout'][data-collapsed='true'] .left-sidebar {
    max-width: calc(0.25rem * 16);
  }

  [data-layout='layout'][data-collapsed='false'] .left-sidebar {
    max-width: calc(0.25rem * 64);
  }

  [data-layout='layout'] .left-sidebar {
    width: 100%;
    min-width: calc(0.25rem * 16);
    max-width: calc(0.25rem * 64);
    transition: max-width 0.25s ease-in-out;
  }

  .left-sidebar {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  [data-collapsed='true'] .left-sidebar ul > li {
    position: relative;
  }
}

.left-sidebar {
  position: fixed;
  transition: max-width 0.25s ease-in-out;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.disable-scrollbars {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-rounded {
  border-radius: 9999px !important;
}

.ant-modal-footer {
  .ant-btn-dangerous {
    background: #ff4d4f;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
